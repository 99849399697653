<style lang="scss">
@import "~@/assets/css/var";

.overview-inv-sale {
    &__title {
        margin: 20px 20px 0;
        font-size: 14px;

        .title {
            font-weight: bold;
            margin-bottom: 10px;
        }
    }

    .make-table {
        height: 100%;

        .my-table {
            height: 100%;
            padding-top: 0;
        }
    }
}
</style>

<template>
    <div class="overview-inv-sale corp-sale flex-item" :style="{'justify-content':error === 'A0004'?'center':'unset'}">
        <template v-if="error === 'A0004'">
            <ts-error style="width: 100%;"></ts-error>
        </template>
        <template v-else>
            <div class="overview-inv-sale__title">
                <ts-table-operate :cols="cols" table-name="sale_year" @handleFilter="handleFilter"
                    @searchShowLeft="searchShowLeft">
                    <template #left>
                        <div style="display: flex; align-items: center;flex: 1;">
                            <div>筛选</div>
                            <el-select style="width: 150px; padding: 0 10px;" v-model="comObj.value" filterable remote
                                reserve-keyword placeholder="请输入药品名称" :remote-method="remoteMethodCom" clearable
                                :loading="comObj.loading" @change="onSearch">
                                <el-option v-for="item in comObj.options" :key="item.value" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </div>
                    </template>
                </ts-table-operate>
            </div>
            <div class="make-table">
                <ts-table :cols="cols" hide-border :data="list" full zero-to-empty :page-param="pageParam"
                    :loading="loading" hide-detail :height="null" @handleSizeChange="handleSizeChange"
                    @handleSort="sort" stop-empty-hide :cmds="cmds" @handleCurrentChange="handleCurrentChange">
                    <template #searchLeft>
                        <ts-search-left v-if="isSearchLeft" :searchCols="searchCols" table-name="sale_year" :cols="cols"
                            @searchClose="searchClose" @handleFilter="handleFilter"></ts-search-left>
                    </template>
                </ts-table>
            </div>
        </template>
    </div>
</template>

<script>
import myMixin from '@/util/mixin'
// import variable from "@/util/variable";
export default {
    mixins: [myMixin],
    data() {
        return {
            loading: false,
            stop: true,
            tableName: 'sale_year',
            pageApi: 'overview/saleYear/sumAmountByComCompSpecProvince',
            conditionList: [],
            cmds: [],
            cols: this.$help.generalCols([
                {
                    fieldName: 'provinceName',
                    name: '省份',
                    width: 50,
                    listQueryView: '是',
                    listQueryModel: 0,
                    listOrderView: '是'
                },
                {
                    fieldName: 'unifySpec',
                    name: '规格',
                    width: 50,
                    listQueryView: '是',
                    listQueryModel: 1,
                    listOrderView: '是'
                    // listStipulate: 1
                },
                {
                    fieldName: 'compName',
                    name: '企业',
                    width: 100,
                    listQueryView: '是',
                    listQueryModel: 1,
                    listOrderView: '是'
                    // listStipulate: 1
                },
                ...this.$variable.getYearCols({
                    listOrderView: '是' // true 就是本地排
                }),
            ]),
            comObj: {
                value: '',
                loading: false,
                options: []
            },
        }
    },
    created() {
        this.remoteMethodCom('氯化钠注射液', true)
    },
    mounted() { },
    methods: {
        //药品
        remoteMethodCom(query = '', bool = false) {
            this.comObj.value = ''
            this.comObj.loading = true
            this.$api.get('setting/aimBaseDrugs/queryAimBaseDrugsListByKeyword', {
                keyword: query
            }).then(res => {
                let options = []
                if (res.data && res.data.length !== 0) {
                    res.data.forEach(ele => {
                        options.push({
                            value: ele.comCode,
                            label: ele.comName
                        })
                    })
                }
                this.comObj.options = options
                this.$nextTick(() => {
                    if (options.length !== 0 && bool) {
                        this.comObj.value = options[0].value
                        this.init()
                    }
                })
            }).finally(() => {
                this.comObj.loading = false
            })
        },

        paramFunc(query) {
            if (this.comObj.value) {
                query.conditionList = [{
                    name: "comCode",
                    op: "eq",
                    value: this.comObj.value
                }]
            }
            return query
        },

        exportInfo(txt = '确定导出吗') {
            if(this.error === 'A0004') return false
            const search = this.getParam()
            delete search.sort
            delete search.page
            this.$help.exportInfo(txt, "overview/saleYear/exportSumAmountByComCompSpecProvince", search)
        },

        onSearch() {
            this.init()
        },
    }
}
</script>